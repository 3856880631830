import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import { EventType } from './customerEvents';
import { createSelector } from 'reselect';
import { ReduxState } from 'ducks';
import { Reservation } from 'models/reservation';

// Actions
const MARKETING_AUTOMATION_CAMPAIGNS_REQUEST = 'MARKETING_AUTOMATION_CAMPAIGNS_REQUEST';
export const MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS = 'MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS';
const MARKETING_AUTOMATION_CAMPAIGNS_FAILURE = 'MARKETING_AUTOMATION_CAMPAIGNS_FAILURE';

// Action creators
const marketingAutomationCampaignsRequest = () =>
  createAction(MARKETING_AUTOMATION_CAMPAIGNS_REQUEST);
const marketingAutomationCampaignsSuccess = (payload: MarketingAutomationCampaign[]) =>
  createAction(MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS, payload);
const marketingAutomationCampaignsFailure = (payload: string) =>
  createAction(MARKETING_AUTOMATION_CAMPAIGNS_FAILURE, payload);

export const fetchMarketingAutomationCampaigns = (
  apiKey: string,
  reservationId: string,
  eventType: EventType,
  contentLanguage: string,
  eventMetadata?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(marketingAutomationCampaignsRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${reservationId}/marketingautomationcampaigns`, {
      params: {
        customer_event_type: eventType,
        customer_event_metadata: eventMetadata,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(marketingAutomationCampaignsSuccess(response.data.marketing_automation_campaigns));
    })
    .catch((err) => {
      dispatch(marketingAutomationCampaignsFailure(err.message));
    });
};

// Actions
const MARKETING_AUTOMATION_CAMPAIGN_REQUEST = 'MARKETING_AUTOMATION_CAMPAIGN_REQUEST';
export const MARKETING_AUTOMATION_CAMPAIGN_SUCCESS = 'MARKETING_AUTOMATION_CAMPAIGN_SUCCESS';
const MARKETING_AUTOMATION_CAMPAIGN_FAILURE = 'MARKETING_AUTOMATION_CAMPAIGN_FAILURE';

// Action creators
const marketingAutomationCampaignRequest = () =>
  createAction(MARKETING_AUTOMATION_CAMPAIGN_REQUEST);
const marketingAutomationCampaignSuccess = (payload: MarketingAutomationCampaign) =>
  createAction(MARKETING_AUTOMATION_CAMPAIGN_SUCCESS, payload);
const marketingAutomationCampaignFailure = (payload: string) =>
  createAction(MARKETING_AUTOMATION_CAMPAIGN_FAILURE, payload);

export const fetchMarketingAutomationCampaign = (
  apiKey: string,
  campaignId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(marketingAutomationCampaignRequest());
  return axios
    .get(`${config.apiUrl}/marketingautomationcampaigns/${campaignId}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(marketingAutomationCampaignSuccess(response.data));
    })
    .catch((err) => {
      dispatch(marketingAutomationCampaignFailure(err.message));
    });
};

// Actions
const DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST =
  'DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST';
export const DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS =
  'DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS';
const DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE =
  'DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE';

// Action creators
const displayedMarketingAutomationCampaignsRequest = () =>
  createAction(DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST);
const displayedMarketingAutomationCampaignsSuccess = (payload: MarketingAutomationCampaign[]) =>
  createAction(DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS, payload);
const displayedMarketingAutomationCampaignsFailure = (payload: string) =>
  createAction(DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE, payload);

export const fetchDisplayedMarketingAutomationCampaigns = (
  apiKey: string,
  reservationId: string,
  eventType: EventType,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(displayedMarketingAutomationCampaignsRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${reservationId}/marketingautomationcampaigns/displayed`, {
      params: { customer_event_type: eventType },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(
        displayedMarketingAutomationCampaignsSuccess(response.data.marketing_automation_campaigns)
      );
    })
    .catch((err) => {
      dispatch(displayedMarketingAutomationCampaignsFailure(err.message));
    });
};

// Actions
const SET_MARKETING_AUTOMATION_CAMPAIGN_RESERVATION =
  'SET_MARKETING_AUTOMATION_CAMPAIGN_RESERVATION';

// Action creators
export const setMarketingAutomationCampaignReservation = (payload: Reservation) =>
  createAction(SET_MARKETING_AUTOMATION_CAMPAIGN_RESERVATION, payload);

type Action =
  | ReturnType<typeof marketingAutomationCampaignsRequest>
  | ReturnType<typeof marketingAutomationCampaignsSuccess>
  | ReturnType<typeof marketingAutomationCampaignsFailure>
  | ReturnType<typeof marketingAutomationCampaignRequest>
  | ReturnType<typeof marketingAutomationCampaignSuccess>
  | ReturnType<typeof marketingAutomationCampaignFailure>
  | ReturnType<typeof displayedMarketingAutomationCampaignsRequest>
  | ReturnType<typeof displayedMarketingAutomationCampaignsSuccess>
  | ReturnType<typeof displayedMarketingAutomationCampaignsFailure>
  | ReturnType<typeof setMarketingAutomationCampaignReservation>;

export const selectApplicableMarketingAutomationCampaigns = createSelector(
  (state: ReduxState) => state.marketingAutomationCampaigns.all,
  (campaigns: MarketingAutomationCampaign[]) =>
    campaigns.filter((c) => c.content.type === 'WEBSITE_POPUP')
);

export const selectDisplayedMarketingAutomationCampaigns = (state: ReduxState) =>
  state.marketingAutomationCampaigns.displayed;

export const selectMarketingAutomationCampaignReservation = (state: ReduxState) =>
  state.marketingAutomationCampaigns.marketingAutomationCampaignReservation;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case MARKETING_AUTOMATION_CAMPAIGNS_FAILURE:
    case MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
      return action.payload;
    case MARKETING_AUTOMATION_CAMPAIGNS_REQUEST:
    case MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS:
    case MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
    case MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: MarketingAutomationCampaign[] = [], action: Action) => {
  switch (action.type) {
    case MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS:
      return action.payload;
    case MARKETING_AUTOMATION_CAMPAIGNS_REQUEST:
    case MARKETING_AUTOMATION_CAMPAIGNS_FAILURE:
      return [];
    default:
      return state;
  }
};

const displayed = (state: MarketingAutomationCampaign[] = [], action: Action) => {
  switch (action.type) {
    case DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS:
      return action.payload;
    case DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST:
    case DISPLAYED_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE:
      return [];
    default:
      return state;
  }
};

const lastFetchedMarketingAutomationCampaign = (
  state: MarketingAutomationCampaign | null = null,
  action: Action
) => {
  switch (action.type) {
    case MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
      return action.payload;
    case MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
    case MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
      return null;
    default:
      return state;
  }
};

const marketingAutomationCampaignReservation = (
  state: Reservation | null = null,
  action: Action
) => {
  switch (action.type) {
    case SET_MARKETING_AUTOMATION_CAMPAIGN_RESERVATION:
      return action.payload;
    default:
      return state;
  }
};

export interface MarketingAutomationCampaignsState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  displayed: ReturnType<typeof displayed>;
  lastFetchedMarketingAutomationCampaign: ReturnType<typeof lastFetchedMarketingAutomationCampaign>;
  marketingAutomationCampaignReservation: ReturnType<typeof marketingAutomationCampaignReservation>;
}

export default combineReducers({
  error,
  all,
  displayed,
  lastFetchedMarketingAutomationCampaign,
  marketingAutomationCampaignReservation,
});
